<template>
  <section id="public-professionals-closer" class="city-find">
    <div class="container">
      <div class="city-find__inner">
        <h2 class="city-find-form__title ui-h2 index-page__section-title">
          {{ title }}
        </h2>
        <div class="city-find-form__subtitle index-page__section-subtitle">
          {{ subtitle }}
        </div>
      </div>
    </div>

    <div class="city-find__map-wrap">
      <div class="city-find__map">
        <img
          src="/images/map.webp"
          loading="lazy"
          alt="Профессионалы ближе, чем Вы думаете"
          class="city-find__map-img"
          height="600"
          width="1440"
        />
      </div>
      <div class="container">
        <div class="city-find__form-wrap">
          <form :action="false" class="city-find__form city-find-form" :class="extended ? '-extended' : ''">
            <h3 class="city-find-form__title ui-h3">Не нашли, что искали?</h3>
            <div class="city-find-form__subtitle">
              <p>Заполните форму и мы все сделаем сами</p>
            </div>
            <ProfessionalsCloserStepsTitles :extended="extended" />
            <ProfessionalsCloserSteps :extended="extended" />
          </form>
        </div>
      </div>
    </div>
    <SuccessModal :title="successModal.title" :text="successModal.text" />
  </section>
</template>

<script>
import ProfessionalsCloserSteps from '@/components/shared/ProfessionalsCloser/ProfessionalsCloserSteps/ProfessionalsCloserSteps'
import ProfessionalsCloserStepsTitles from '@/components/shared/ProfessionalsCloser/ProfessionalsCloserStepsTitles/ProfessionalsCloserStepsTitles'
import SuccessModal from '@/components/SuccessModal'

export default {
  name: 'ProfessionalsCloser',
  components: {
    SuccessModal,
    ProfessionalsCloserStepsTitles,
    ProfessionalsCloserSteps,
  },
  props: {
    // расширенная форма для иностранных лэндингов
    extended: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      title: 'Профессионалы ближе, чем Вы думаете',
      subtitle: 'Почти в каждом городе есть наш сертифицированный монтажник',
      services: [
        'Монтаж систем отопления',
        'Монтаж систем водоснабжения',
        'Монтаж систем водоотведения',
        'Монтаж напольного отопления',
        'Монтаж систем автоматики',
      ],
      modalMessage: '',
      loginModalOpen: false,
      successModal: {
        title: 'Ваша заявка принята',
        text: `Спасибо! Заявка принята. Мы перезвоним Вам в ближайшее время.
        Работаем каждый день с 08.00 до 20.00 МСК.`,
      },
    }
  },
}
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '../../../assets/scss/index/professionalsCloser';
</style>
