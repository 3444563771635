<template>
  <div class="city-find-form__step city-find-form__step--last">
    <div class="city-find-form__radio-wrap">
      <h3 class="city-find-form__radio-title">Объект</h3>
      <label class="ui-form__radio-label">
        <input v-model="object" type="radio" name="object" class="ui-form__radio" value="Дом" />
        <span class="ui-form__radio-text"> Дом </span>
      </label>
      <label class="ui-form__radio-label">
        <input v-model="object" type="radio" name="object" class="ui-form__radio" value="Квартира" />
        <span class="ui-form__radio-text"> Квартира </span>
      </label>
    </div>
    <div class="city-find-form__radio-wrap">
      <h3 class="city-find-form__radio-title">Метраж</h3>
      <label class="ui-form__radio-label">
        <input v-model="size" type="radio" name="size" class="ui-form__radio" value="менее 100 метров" />
        <span class="ui-form__radio-text"> менее 100 метров </span>
      </label>
      <label class="ui-form__radio-label">
        <input v-model="size" type="radio" name="size" class="ui-form__radio" value="более 100 метров" />
        <span class="ui-form__radio-text"> более 100 метров </span>
      </label>
      <label class="ui-form__radio-label">
        <input v-model="size" type="radio" name="size" class="ui-form__radio" value="более 250 метров" />
        <span class="ui-form__radio-text"> более 250 метров</span>
      </label>
    </div>
    <StepFiveSendButton :disabled="!object || !size" />
    <p v-if="!extended" class="city-find-form__agree">
      Нажимая на кнопку «Отправить заявку» я даю согласие на обработку моих персональных данных в соответствии с
      <button type="button" target="_blank" @click="showConsentModal">условиями</button>.
    </p>
    <PersonalDataConsentModal />
  </div>
</template>

<script>
import PersonalDataConsentModal from '@/components/shared/PersonalDataConsentModal'
import StepFiveSendButton from '@/components/shared/ProfessionalsCloser/ProfessionalsCloserSteps/StepFive/StepFiveSendButton'

export default {
  name: 'StepFive',
  components: {
    StepFiveSendButton,
    PersonalDataConsentModal,
  },
  data() {
    return {
      isValidNumber: false,
    }
  },
  computed: {
    object: {
      get() {
        return this.$store.state.pages.home.professionalsCloser.form.object
      },
      set(value) {
        this.$store.dispatch('pages/home/professionalsCloser/updateFormField', {
          fieldName: 'object',
          fieldValue: value,
        })
      },
    },
    size: {
      get() {
        return this.$store.state.pages.home.professionalsCloser.form.size
      },
      set(value) {
        this.$store.dispatch('pages/home/professionalsCloser/updateFormField', {
          fieldName: 'size',
          fieldValue: value,
        })
      },
    },
  },
  methods: {
    showConsentModal() {
      this.$store.dispatch('modules/modals/updateModal', {
        name: 'personalDataConsent',
        status: true,
      })
    },
  },
}
</script>

<style scoped></style>
