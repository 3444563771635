export const examples = [
  {
    img: require('@/assets/images/examples/example-1.jpeg'),
    title: 'Отопление в беседке системой теплых полов',
    worker: {
      avatar: require('@/assets/images/examples/example-installer-1.jpeg'),
      name: 'Константин Ташлинцев',
    },
    to: '/photos/000145/296',
  },
  {
    img: require('@/assets/images/examples/example-2.jpeg'),
    title: 'Таунхаус',
    worker: {
      avatar: require('@/assets/images/examples/example-installer-1.jpeg'),
      name: 'Константин Ташлинцев',
    },
    to: '/photos/000145/296',
  },
  {
    img: require('@/assets/images/examples/example-3.jpeg'),
    title: 'Отопление и водоснабжение в ЖК The Mid',
    worker: {
      avatar: require('@/assets/images/examples/example-installer-2.jpeg'),
      name: 'Роман Акимов',
    },
    to: '/photos/000022/460',
  },
  {
    img: require('@/assets/images/examples/example-4.jpeg'),
    title: 'ЖК ВТБ Арена',
    worker: {
      avatar: require('@/assets/images/examples/example-installer-2.jpeg'),
      name: 'Роман Акимов',
    },
    to: '/photos/000022/57',
  },
  {
    img: require('@/assets/images/examples/example-5.jpeg'),
    title: 'Система водоснабжения в квартире',
    worker: {
      avatar: require('@/assets/images/examples/example-installer-3.jpeg'),
      name: 'Алексей Бородин',
    },
    to: '/photos/000206/482',
  },
  {
    img: require('@/assets/images/examples/example-6.jpeg'),
    title: 'Узел ввода воды',
    worker: {
      avatar: require('@/assets/images/examples/example-installer-4.jpeg'),
      name: 'Иван Анисифоров',
    },
    to: '/photos/001495/485',
  },
  {
    img: require('@/assets/images/examples/example-7.jpeg'),
    title: 'Частный дом - Всеволожск-Липовая аллея',
    worker: {
      avatar: require('@/assets/images/examples/example-installer-5.jpeg'),
      name: 'Алексей Заугольников',
    },
    to: '/photos/002064/282',
  },
]
