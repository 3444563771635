<template>
  <section id="about-rehau" class="holding">
    <div class="container">
      <div class="holding__inner">
        <h2 class="holding__title ui-h2 index-page__section-title">
          {{ COMPANY.fullName }} &ndash; часть семьи {{ COMPANY.name }}
        </h2>
        <div class="holding__subtitle index-page__section-subtitle">
          <p>Лайфхаки, инсайты и прочее о ремонте, стройке и других сложных вещах</p>
        </div>
        <div class="holding__row">
          <div class="holding__col -video">
            <img
              src="/images/video-poster-2.webp"
              class="temp-image"
              loading="lazy"
              :alt="`${COMPANY.fullName} - часть семьи ${COMPANY.name}`"
              height="510"
              width="690"
            />
          </div>
          <!--          <div class="holding__col -video temporary-image">-->
          <!--            <div class="holding__video-wrap">-->
          <!--              <iframe v-resize src="https://www.youtube.com/embed/jfQWIE2ekhA" class="iframe" />-->
          <!--              <button-->
          <!--                ref="videoPlayBtn"-->
          <!--                class="holding__video-play-btn"-->
          <!--                @click="handleVideoPlayBtnClick"-->
          <!--              >-->
          <!--                <svg-->
          <!--                  width="84"-->
          <!--                  height="84"-->
          <!--                  viewBox="0 0 84 84"-->
          <!--                  fill="none"-->
          <!--                  xmlns="http://www.w3.org/2000/svg"-->
          <!--                >-->
          <!--                  <path-->
          <!--                    fill-rule="evenodd"-->
          <!--                    clip-rule="evenodd"-->
          <!--                    d="M42 78C61.8823 78 78 61.8823 78 42C78 22.1177 61.8823 6 42 6C22.1177 6 6 22.1177 6 42C6 61.8823 22.1177 78 42 78ZM42 84C65.196 84 84 65.196 84 42C84 18.804 65.196 0 42 0C18.804 0 0 18.804 0 42C0 65.196 18.804 84 42 84Z"-->
          <!--                    fill="white"-->
          <!--                  />-->
          <!--                  <path-->
          <!--                    d="M62.5 41.134C63.1667 41.5189 63.1667 42.4811 62.5 42.866L32.5 60.1865C31.8333 60.5714 31 60.0903 31 59.3205L31 24.6795C31 23.9097 31.8333 23.4285 32.5 23.8134L62.5 41.134Z"-->
          <!--                    fill="white"-->
          <!--                  />-->
          <!--                </svg>-->
          <!--              </button>-->
          <!--              <video-->
          <!--                ref="holdingVideo"-->
          <!--                :src="video.src"-->
          <!--                class="holding__video"-->
          <!--                :poster="video.poster"-->
          <!--              ></video>-->
          <!--            </div>-->
          <!-- <div class="holding__video-title">{{ video.title }}</div> -->
          <!--          </div>-->
          <div class="holding__col -text">
            <div class="holding__text">
              <p>
                Профессиональное сообщество монтажников {{ COMPANY.fullName }} &ndash; официальный каталог специалистов
                по установке систем отопления, водоснабжения, водоотведения и автоматизации в частных домах и квартирах.
              </p>
              <p>
                Выберите специалиста в своем регионе в каталоге сертифицированных монтажников, закажите услугу и
                получите результат качественно и в срок. Найти профессионала для вашей задачи просто: в портфолио
                специалистов указан опыт работы, типы объектов, специализация, доступны сведения о выполненных заказах,
                отзывы реальных клиентов. Для быстрого поиска можно настроить фильтр по указанным параметрам, выбрать
                компанию, ИП, частного специалиста.
              </p>
              <p>
                Заявки отслеживаются в личном кабинете, заказать монтаж можно самостоятельно или оставив запрос на поиск
                подрядчика на официальном сайте. Все специалисты проходят обязательную сертификацию для допуска к работе
                с технологиями и материалами {{ COMPANY.name }} &ndash; основой безопасности, надежности и долговечности
                ремонта в вашем доме.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { COMPANY } from '@/constants/global.constant'

export default {
  name: 'AboutRehau',
  data() {
    return {
      COMPANY,
    }
  },
  methods: {
    // async handleVideoPlayBtnClick() {
    //   const holdingVideo = this.$refs.holdingVideo
    //   const holdingVideoPlayBtn = this.$refs.videoPlayBtn
    //   try {
    //     await holdingVideo.play()
    //     holdingVideoPlayBtn.classList.add('js-playing')
    //     holdingVideo.setAttribute('controls', 'controls')
    //   } catch (err) {
    //     holdingVideoPlayBtn.classList.remove('js-playing')
    //   }
    // },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/index/_about-rehau.scss';
.temp-image {
  width: 100%;
  height: auto;
}
//.iframe {
//  border: 0;
//  width: 100%;
//  height: 100%;
//  display: block;
//}
//.temporary-image {
//  background: url('~@/assets/images/content/video-poster-2.jpg') center/cover no-repeat;
//  @media (max-width: 991px) {
//    background-position: top;
//  }
//}
</style>
