<template>
  <ul class="city-find-form__step-title-list">
    <li class="city-find-form__step-title js-colored">
      <span class="city-find-form__step-title-text"> 1.Город </span>
      <span class="city-find-form__step-title-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.9786 7.99998L3.55469 1.50943L5.04862 0L12.9665 7.99998L5.04862 16L3.55469 14.4906L9.9786 7.99998Z"
          />
        </svg>
      </span>
    </li>
    <li :class="['city-find-form__step-title', step >= 2 ? 'js-colored' : '']">
      <span class="city-find-form__step-title-text"> 2.Услуга </span>
      <span class="city-find-form__step-title-icon">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.9786 7.99998L3.55469 1.50943L5.04862 0L12.9665 7.99998L5.04862 16L3.55469 14.4906L9.9786 7.99998Z"
          />
        </svg>
      </span>
    </li>
    <li :class="['city-find-form__step-title', step >= 3 ? 'js-colored' : '']">
      <span class="city-find-form__step-title-text"> 3.Телефон </span>
      <span class="city-find-form__step-title-icon">
        <svg
          v-if="extended === true"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="black"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.9786 7.99998L3.55469 1.50943L5.04862 0L12.9665 7.99998L5.04862 16L3.55469 14.4906L9.9786 7.99998Z"
          />
        </svg>
      </span>
    </li>
    <li v-if="extended === true" :class="['city-find-form__step-title', step >= 4 ? 'js-colored' : '']">
      <span class="city-find-form__step-title-text"> 4.Имя </span>
      <span class="city-find-form__step-title-icon"
        ><svg width="16" height="16" viewBox="0 0 16 16" fill="black" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.9786 7.99998L3.55469 1.50943L5.04862 0L12.9665 7.99998L5.04862 16L3.55469 14.4906L9.9786 7.99998Z"
          />
        </svg>
      </span>
    </li>
    <li v-if="extended === true" :class="['city-find-form__step-title', step === 5 ? 'js-colored' : '']">
      <span class="city-find-form__step-title-text"> 5.Объект </span>
      <span class="city-find-form__step-title-icon"> </span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ProfessionalsCloserStepsTitles',
  props: {
    extended: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    step() {
      return this.$store.state.pages.home.professionalsCloser.step
    },
  },
}
</script>

<style scoped></style>
