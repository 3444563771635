<template>
  <router-link v-slot="{ href, navigate }" :to="example.to" custom>
    <a :href="href" class="works-slide__link" @click="navigate">
      <div class="works-slide__img-wrap">
        <img
          :src="example.img"
          loading="lazy"
          :alt="`Примеры выполненных работ ${example.title}`"
          class="works-slide__img"
        />
      </div>
      <div class="works-slide__bottom">
        <h3 class="works-slide__title ui-h4">
          {{ example.title }}
        </h3>
        <div class="works-slide__worker">
          <div v-if="example.worker.avatar" class="works-slide__worker-avatar-wrap">
            <img :src="example.worker.avatar" alt="" class="works-slide__worker-avatar-img" />
          </div>
          <div class="works-slide__worker-info">
            <div class="works-slide__worker-profession">Монтажник:</div>
            <div class="works-slide__worker-name">
              {{ example.worker.name }}
            </div>
          </div>
        </div>
      </div>
    </a>
  </router-link>
</template>

<script>
export default {
  name: 'ExamplesSlideLink',
  props: {
    example: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
