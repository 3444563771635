<template>
  <client-only>
    <section id="public-examples" class="works">
      <div class="container">
        <ExamplesTitle :title="title" :subtitle="subtitle" />
      </div>

      <div class="works__slider-wrap">
        <div class="works__slider-arrows index-page__slider-arrows">
          <SwiperCircleArrows :examples="examples" />
        </div>
        <ExamplesSlider :examples="examples" />
      </div>
    </section>
  </client-only>
</template>

<script>
import ExamplesSlider from '@/components/shared/Examples/ExamplesSlider'
import ExamplesTitle from '@/components/shared/Examples/ExamplesTitle'
import SwiperCircleArrows from '@/components/shared/Swiper/SwiperCircleArrows'

export default {
  name: 'Examples',
  components: {
    SwiperCircleArrows,
    ExamplesTitle,
    ExamplesSlider,
  },
  props: {
    examples: {
      type: Array,
      default() {
        return []
      },
    },
    title: {
      type: String,
      default: 'Примеры выполненных работ',
    },
    subtitle: {
      type: String,
      default: 'Посмотрите как профессионалы превращают Вашу задачу в свою работу',
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/index/examples.scss';
</style>
