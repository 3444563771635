<template>
  <Modal
    v-if="showModal"
    :fullscreen-on-mobile="false"
    :show-close-button="true"
    :modal-name-in-store="modalNameInStore"
  >
    <div class="success-modal">
      <div class="title">{{ title }}</div>
      <div class="text">{{ text }}</div>
      <div class="controls">
        <button class="ui-btn -cta" @click="closeModal">
          <span class="ui-btn__text">Закрыть</span>
          <span class="ui-btn__icon">
            <img src="@/assets/images/icons/angle-right-white.svg" alt="icon" />
          </span>
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/Modal'
export default {
  name: 'SuccessModal',
  components: { Modal },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      modalNameInStore: 'success',
    }
  },
  computed: {
    showModal() {
      return this.$store.state.modules.modals.modals[this.modalNameInStore]
    },
  },
  methods: {
    closeModal() {
      this.$store.dispatch('modules/modals/updateModal', {
        name: this.modalNameInStore,
        status: false,
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import 'assets/scss/ui/ui-btn';

.success-modal {
  padding: 2vw;
  width: 60vw;
}
.title {
  font-size: 18px;
  font-weight: 600;
}
.text {
  padding-top: 16px;
  font-size: 16px;
  font-weight: 400;
}
.controls {
  padding-top: 16px;
}
.ui-btn__text {
  font-size: 16px;
}
</style>
