<template>
  <div class="works__inner">
    <h2 class="works__title ui-h2 index-page__section-title">
      {{ title }}
    </h2>
    <div class="works__text index-page__section-subtitle">
      <p>{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamplesTitle',
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
}
</script>
