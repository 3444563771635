<template>
  <div>
    <button type="button" class="city-find-form__next-btn ui-btn -cta" :disabled="disabled" @click="clickSendDebounced">
      <span class="ui-btn__text">Отправить заявку</span>
      <span class="ui-btn__icon">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.2269 8.99998L4 1.69811L5.68067 0L14.5882 8.99998L5.68067 18L4 16.3019L11.2269 8.99998Z"
            fill="white"
          /></svg
      ></span>
    </button>
  </div>
</template>

<script>
import { debounce } from 'lodash'

import { FOREIGN_LANDINGS_COUNTRIES } from '@/constants/foreign-landings/foreign-landings.constants'
export default {
  name: 'StepFiveSendButton',
  props: {
    disabled: {
      type: Boolean,
      required: true,
      default: true,
    },
    country: {
      type: String,
      default: '',
    },
  },
  methods: {
    clickSendDebounced: debounce(async function () {
      const isForeignLanding = Boolean(this.country)
      if (isForeignLanding) {
        this.$store.dispatch('pages/home/professionalsCloser/updateFormField', {
          fieldName: 'type',
          fieldValue: `ASK_QUESTION_${FOREIGN_LANDINGS_COUNTRIES[this.country].codeCapitalized}_CLIENT`,
        })
      }
      const form = this.$store.state.pages.home.professionalsCloser.form
      const resultOfSending = await this.$axios.post('/custapi/allrest/int/saveint', form)
      if (resultOfSending.status === 200) {
        await this.$store.dispatch('modules/modals/updateModal', {
          name: 'success',
          status: true,
        })
        await this.$store.dispatch('pages/home/professionalsCloser/resetAll')
        // this.$gtm.push({
        //  event: 'reachGoal',
        //  target: 'Goals',
        //  action: 'NoFoundThatSearchIndexPage',
        // })
      }
    }, 300),
  },
}
</script>

<style scoped></style>
