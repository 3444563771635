<template>
  <client-only>
    <section id="popular-services" class="popular">
      <div class="container">
        <div class="popular__inner">
          <h1 v-if="titleAsH1" class="popular__title ui-h2 index-page__section-title">
            {{ title }}
          </h1>
          <h2 v-else class="popular__title ui-h2 index-page__section-title">
            {{ title }}
          </h2>
          <div class="popular__subtitle index-page__section-subtitle">
            <p>
              {{ subtitle }}
            </p>
          </div>
        </div>
      </div>
      <div class="popular__slider-wrap">
        <div class="popular__slider-arrows index-page__slider-arrows">
          <div class="swiper-button-prev">
            <svg width="20" height="16" viewBox="0 0 20 16" fill="black" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.59844 0.679687L0.398438 7.87969L7.59844 15.0797L9.29644 15.0797L2.69644 8.47969L19.5984 8.47969V7.27969L2.69644 7.27969L9.29644 0.679687L7.59844 0.679687Z"
              />
            </svg>
          </div>
          <div class="swiper-button-next" data-note="0">
            <!--            <div class="circle">-->
            <!--              <svg width="36" height="36" class="circle__svg">-->
            <!--                <circle-->
            <!--                  cx="18"-->
            <!--                  cy="18"-->
            <!--                  r="16"-->
            <!--                  class="circle__progress circle__progress&#45;&#45;path"-->
            <!--                ></circle>-->
            <!--                <circle-->
            <!--                  cx="18"-->
            <!--                  cy="18"-->
            <!--                  r="16"-->
            <!--                  class="circle__progress circle__progress&#45;&#45;fill"-->
            <!--                ></circle>-->
            <!--              </svg>-->
            <!--            </div>-->
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
              class="swiper-button-next__svg"
            >
              <path
                d="M12.3977 15.0801L19.5977 7.88008L12.3977 0.680077L10.6997 0.680077L17.2997 7.28008L0.397658 7.28008L0.397658 8.48008L17.2997 8.48008L10.6997 15.0801L12.3977 15.0801Z"
              />
            </svg>
          </div>
        </div>
        <swiper ref="popularSwiper" class="swiper-container swiper" :options="swiperOptions">
          <swiper-slide v-for="service in services" :key="service.title" class="popular__slide swiper-slide">
            <SquareLinkCard :img="service.img" :title="service.title" @card-click="clickPopularService(service.to)" />
          </swiper-slide>
        </swiper>
      </div>
    </section>
  </client-only>
</template>

<script>
import SquareLinkCard from '@/components/ui/Boxes/SquareLinkCard'
import { COMPANY } from '@/constants/global.constant'

export default {
  name: 'IndexPopularServices',
  components: { SquareLinkCard },
  props: {
    country: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: () => `Популярные услуги ${COMPANY.name}`,
    },
    titleAsH1: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      COMPANY,
      subtitle: 'Наши специалисты готовы помочь вам в решении самых разнообразных задач в сфере монтажа.',
      services: [
        {
          service: 'sistema-otopleniya-ohlazhdeniya',
          img: require('@/assets/images/icons/popular-services/services-1.svg'),
          title: 'Монтаж систем отопления',
          to: '/resheniya-dlya-domov/sistema-otopleniya/',
        },
        {
          service: 'vodosnabzhenie',
          img: require('@/assets/images/icons/popular-services/services-2.svg'),
          title: 'Монтаж систем водоснабжения',
          to: '/resheniya-dlya-domov/vodosnabzhenie/',
        },
        {
          service: 'elektricheskiy-teplyy-pol',
          img: require('@/assets/images/icons/popular-services/services-4.svg'),
          title: 'Монтаж напольного отопления',
          to: '/resheniya-dlya-domov/teplye-poly/',
        },
        {
          service: 'avtomatika',
          img: require('@/assets/images/icons/popular-services/services-5.svg'),
          title: 'Монтаж систем автоматики',
          to: '/resheniya-dlya-domov/zashhita-ot-protechek/',
        },
      ],
      swiperOptions: {
        navigation: {
          nextEl: '.popular__slider-wrap .swiper-button-next',
          prevEl: '.popular__slider-wrap .swiper-button-prev',
        },
        spaceBetween: 20,
        slidesPerView: 4,
        allowTouchMove: false,
        slidesOffsetBefore: 0,
        slidesOffsetafter: 0,
        autoplay: {
          delay: 6000,
        },
        breakpoints: {
          1199: {
            allowTouchMove: true,
            slidesPerView: 'auto',
            slidesOffsetBefore: 20,
            slidesOffsetAfter: 20,
            autoplay: {
              delay: 6000,
            },
          },
          479: {
            allowTouchMove: true,
            slidesPerView: 'auto',
            slidesOffsetBefore: 12,
            slidesOffsetAfter: 12,
          },
        },
      },
    }
  },
  methods: {
    clickPopularService(to) {
      this.$router.push(to)
    },
  },
}
</script>

<style scoped lang="scss">
@import '/assets/scss/index/popular';
.container {
  overflow: hidden;
}
</style>
