<template>
  <div class="works-slide__inner blog-popular__slide">
    <a :href="post.link" class="works-slide__link">
      <div class="works-slide__img-wrap">
        <img
          :src="post.image ? post.image : require('@/assets/images/icons/rehau-pro/image_placeholder.jpg')"
          :alt="post.post_title"
          loading="lazy"
          class="works-slide__img"
          @error="$event.target.src = require('@/assets/images/icons/rehau-pro/image_placeholder.jpg')"
        />
      </div>
      <div class="works-slide__bottom">
        <div v-if="post.post_date" class="works-slide__date">
          {{
            new Date(post.post_date).toLocaleString('ru', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              timezone: 'UTC',
            })
          }}
        </div>
        <h3 class="works-slide__name js-cropp-title" v-html="post.post_title"></h3>
        <div class="works-slide__content" v-html="post.post_content"></div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: 'MostPopularSlide',
  props: {
    post: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.works-slide {
  &__date {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $black-60;

    margin-bottom: 8px;
    text-decoration: none;
  }
  &__name {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    height: 72px;
    overflow: hidden;
    color: $black;
    margin-bottom: 8px;
    text-decoration: none;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    //@media (max-width: $mobile) {
    //height: 95px;
    //}
  }
  &__content {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    height: 100px;
    overflow: hidden;
    color: $black;

    @media (max-width: $mobile) {
      height: 120px;
    }
  }
  &__img-wrap {
    display: block;
  }
}
</style>
