<template>
  <div class="banner__slider-container">
    <client-only>
      <swiper
        ref="indexBannerSwiper"
        class="swiper-container swiper banner__slider"
        :options="swiperOptions"
        @init="onInitSwiper"
        @slide-change-transition-start="onTransitionStart"
        @transition-end="onTransitionEnd"
        @update="strokeReset"
      >
        <swiper-slide v-for="(slide, index) in slides" :key="index" class="swiper-slide banner__offer">
          <div class="banner__offer__filter"></div>
          <img
            :src="slide.img"
            alt=""
            :class="['banner__bg-img', index > 0 ? 'center' : '']"
            height="600"
            width="1700"
          />
          <div class="container">
            <div class="banner__offer-inner">
              <h1 class="banner__title" v-html="slide.title"></h1>
              <div class="banner__text" v-html="slide.description"></div>
              <button v-if="slide.actionButtonText" class="banner__cta-btn ui-btn -cta" @click="slide.action">
                <span class="ui-btn__text">{{ slide.actionButtonText }}</span>
                <span class="ui-btn__icon">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="white" xmlns="http://www.w3.org/2000/svg">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M11.2269 8.99998L4 1.69811L5.68067 0L14.5882 8.99998L5.68067 18L4 16.3019L11.2269 8.99998Z"
                    />
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="banner__slider__bottom bottom">
        <div class="container bottom__container">
          <div class="bottom__pagination"></div>
          <div class="bottom__arrows">
            <div class="swiper-button-prev">
              <svg width="20" height="16" viewBox="0 0 20 16" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.59844 0.679687L0.398438 7.87969L7.59844 15.0797L9.29644 15.0797L2.69644 8.47969L19.5984 8.47969V7.27969L2.69644 7.27969L9.29644 0.679687L7.59844 0.679687Z"
                />
              </svg>
            </div>
            <div class="swiper-button-next" data-note="0">
              <div class="circle">
                <svg width="36" height="36" class="circle__svg">
                  <circle cx="18" cy="18" r="16" class="circle__progress circle__progress--path"></circle>
                  <circle cx="18" cy="18" r="16" class="circle__progress circle__progress--fill -white"></circle>
                </svg>
              </div>
              <svg
                width="20"
                height="16"
                viewBox="0 0 20 16"
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                class="swiper-button-next__svg"
              >
                <path
                  d="M12.3977 15.0801L19.5977 7.88008L12.3977 0.680077L10.6997 0.680077L17.2997 7.28008L0.397658 7.28008L0.397658 8.48008L17.2997 8.48008L10.6997 15.0801L12.3977 15.0801Z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </client-only>
  </div>
</template>

<script>
import { ref, useRouter } from '@nuxtjs/composition-api'

export default {
  name: 'IndexSlider',
  setup() {
    const router = useRouter()
    const indexBannerSwiper = ref(null)
    const transitionDuration = 6000

    const swiperOptions = {
      autoplay: {
        delay: transitionDuration,
      },
      pagination: {
        el: '.bottom__pagination',
        clickable: false,
      },
      navigation: {
        nextEl: '.bottom__arrows .swiper-button-next',
        prevEl: '.bottom__arrows .swiper-button-prev',
      },
      simulateTouch: false,
      allowTouchMove: true,
      slidesPerView: 1,
    }

    const slides = [
      {
        img: require('@/assets/images/index-slider/slide-4.png'),
        title: 'РЕХАУ.Про Качество',
        description:
          'Проверяйте подлинность товаров и сертификаты дистрибьюторов с помощью нашего приложения. Техподдержка ответит на ваши вопросы и поможет выбрать продукцию.',
        actionButtonText: 'Скачать приложение',
        action: () => {
          router.push('/mobileapp')
        },
      },
      {
        img: require('@/assets/images/index-slider/slide-5.jpg'),
        title: 'Обучение и профессиональное сообщество РЕХАУ',
        description: 'В Академии РЕХАУ вы можете бесплатно освоить новую профессию и повысить квалификацию.',
        actionButtonText: 'Узнать больше',
        action: () => {
          router.push('/education')
        },
      },
      {
        img: require('@/assets/images/index-slider/slide-2.jpg'),
        title: 'R.Garant: гарантия от&nbsp;РЕХАУ с&nbsp;покрытием до&nbsp;100&nbsp;000&nbsp;евро',
        description:
          'Выдавайте гарантию R.Garant на инженерные системы РЕХАУ на объекты с суммарной стоимостью оборудования РЕХАУ от&nbsp;45&nbsp;000&nbsp;рублей.',
        actionButtonText: 'Узнать больше',
        action: () => {
          router.push('/r-garant')
        },
      },
      {
        img: require('@/assets/images/index-slider/slide-3.jpg'),
        title: 'R.Bonus: программа лояльности РЕХАУ',
        description:
          'Покупать РЕХАУ — выгодно. Получайте кешбэк с каждой покупки и легко выводите его на банковский счет.',
        actionButtonText: 'Подробнее о программе',
        action: () => {
          router.push('/r-bonus')
        },
      },
      {
        img: require('@/assets/images/index-slider/slide-1.jpg'),
        title: 'Превосходи стандарты с&nbsp;РЕХАУ',
        description: 'Мы открываем новую страницу в представлениях о надежности и безопасности инженерных систем.',
        action: () => {
          router.push('/reliable')
          /* yandexMetrikaReachGoal("ClickBannerCreateOrder");
          // gtmReachGoal("ClickBannerCreateOrder");
          this.$gtm.push({
            event: 'reachGoal',
            target: 'Goals',
            action: 'ClickBannerCreateOrder',
          })
          // this.$yandexMetrika.reachGoal('ClickBannerCreateOrder')
           */
        },
        actionButtonText: 'Стать первым с РЕХАУ',
      },
    ]

    const strokeTransition = (swiperButtonNext, note) => {
      setTimeout(() => {
        const progress = swiperButtonNext.querySelector('.circle__progress--fill')
        const radius = progress.r.baseVal.value
        const circumference = 2 * Math.PI * radius
        const offset = (circumference * (10 - note)) / 10
        progress.style.setProperty('--initialStroke', circumference)
        progress.style.setProperty('--transitionDuration', `${transitionDuration}ms`)
        progress.style.strokeDashoffset = offset
      }, 0)
    }
    const strokeReset = () => {
      const swiperButtonNext = indexBannerSwiper.value.$el.parentNode.querySelector('.swiper-button-next')
      const note = parseFloat(swiperButtonNext.dataset.note)
      const progress = swiperButtonNext.querySelector('.circle__progress--fill')
      progress.classList.remove('js-hidden')
      progress.style.setProperty('--transitionDuration', '0ms')
      progress.style.setProperty('--initialStroke', '0')
      progress.style.strokeDashoffset = '0'
      strokeTransition(swiperButtonNext, note)
    }
    const strokeDisable = () => {
      const swiperButtonNext = indexBannerSwiper.value.$el.parentNode.querySelector('.swiper-button-next')
      const progress = swiperButtonNext.querySelector('.circle__progress--fill')
      progress.classList.add('js-hidden')
    }

    const onInitSwiper = () => {
      const swiperButtonNext = indexBannerSwiper.value.$el.parentNode.querySelector('.swiper-button-next')
      const note = parseFloat(swiperButtonNext.dataset.note)
      strokeTransition(swiperButtonNext, note)
      strokeReset()
    }

    const onTransitionStart = () => {
      if (!indexBannerSwiper.value.swiper.autoplay.running) {
        strokeDisable()
      }
    }

    const onTransitionEnd = () => {
      if (indexBannerSwiper.value.swiper.autoplay.running) {
        strokeReset()
      }
    }

    return { indexBannerSwiper, swiperOptions, slides, onInitSwiper, onTransitionStart, onTransitionEnd, strokeReset }
  },
}
</script>

<style scoped lang="scss"></style>
