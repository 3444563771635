<template>
  <div class="city-find-form__step-list">
    <StepOne v-if="step === 1" />
    <StepTwo v-if="step === 2" />
    <StepThree v-if="step === 3" :extended="extended" />
    <StepFour v-if="extended && step === 4" />
    <StepFive v-if="extended && step === 5" />
  </div>
</template>

<script>
import StepFive from './StepFive/StepFive'
import StepFour from './StepFour'
import StepOne from './StepOne'
import StepThree from './StepThree/StepThree'
import StepTwo from './StepTwo'
export default {
  name: 'ProfessionalsCloserSteps',
  components: {
    StepThree,
    StepTwo,
    StepOne,
    StepFour,
    StepFive,
  },
  props: {
    extended: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    step() {
      return this.$store.state.pages.home.professionalsCloser.step
    },
  },
}
</script>

<style scoped></style>
