<template>
  <div class="ui-swiper-circle-arrows">
    <div class="swiper-button-prev">
      <svg width="20" height="16" viewBox="0 0 20 16" fill="black" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.59844 0.679687L0.398438 7.87969L7.59844 15.0797L9.29644 15.0797L2.69644 8.47969L19.5984 8.47969V7.27969L2.69644 7.27969L9.29644 0.679687L7.59844 0.679687Z"
        />
      </svg>
    </div>
    <div class="swiper-button-next" data-note="0">
      <div class="circle">
        <svg width="36" height="36" class="circle__svg">
          <circle cx="18" cy="18" r="16" class="circle__progress circle__progress--path"></circle>
          <circle cx="18" cy="18" r="16" class="circle__progress circle__progress--fill"></circle>
        </svg>
      </div>
      <svg
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
        class="swiper-button-next__svg"
      >
        <path
          d="M12.3977 15.0801L19.5977 7.88008L12.3977 0.680077L10.6997 0.680077L17.2997 7.28008L0.397658 7.28008L0.397658 8.48008L17.2997 8.48008L10.6997 15.0801L12.3977 15.0801Z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwiperCircleArrows',
}
</script>
