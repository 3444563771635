<template>
  <button class="ui-button" :class="theme" :disabled="disabled">
    <span class="ui-button-inner">
      <slot />
    </span>
    <span class="ui-button-angle" />
  </button>
</template>

<script>
// Кнопка (пинк) готова к выносу
export default {
  name: 'UIButton',
  props: {
    theme: {
      // 'white' or 'pink'
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/variables';

.ui-button {
  //height: 44px;
  height: 9vw;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  &-inner {
    //margin-left: 20px;
    //margin-right: 10px;
    padding: 0 3.125vw;
    font-weight: 500;
    font-size: 4.6875vw;
    //font-size: 18px;
    // Выравнивание текста по центру
    //line-height: 16px;
    //margin-top: 2px;
  }
  &-angle {
    height: 9vw;
    width: 9vw;
  }
  @media (min-width: 1021px) {
    height: 2.63543vw;
    &-inner {
      font-size: 1.1713vw;
      line-height: 2.63543vw;
      padding: 0 0.73206vw;
    }
    &-angle {
      height: 2.63543vw;
      width: 2.63543vw;
    }
  }
  @media (min-width: 1457px) {
    height: 39px;
    &-inner {
      font-size: 16px;
      padding: 0 11px;
    }
    &-angle {
      height: 39px;
      width: 39px;
    }
  }
}
.pink {
  color: white;
  background: linear-gradient(to right, black 50%, $active-red 50%) right bottom;
  background-size: 200% 100%;
  transition: all 0.2s ease-out;
  &:hover {
    background-position: left bottom;
  }
  &:active {
    .ui-button-angle {
      background: black;
    }
  }
  .ui-button-angle {
    background: $active-red url(@/assets/images/icons/angle-right-white.svg) no-repeat center;
  }
}
.white {
  color: $active-red;
  transition: all 0.2s ease-out;
  background: transparent;
  &:hover {
    background-position: left bottom;
  }
  .ui-button-angle {
    background: transparent url(@/assets/images/icons/angle-right-pink.svg) no-repeat center;
  }
}
</style>
