import axios from 'axios'

import { getConfig } from '@/configs/app.configs'

export async function fetchPopular() {
  try {
    const domain = getConfig('blogApi')

    const res = await axios.get(domain + '/rehau/v1/most-popular-posts', { params: { limit: 3 } })

    return res.data
  } catch (e) {
    return []
  }
}
