<template>
  <div class="city-find-form__step">
    <label class="city-find-form__label ui-form__label">
      <span class="ui-form__label-text">Ваше имя</span>
      <input type="text" class="ui-form__input" placeholder="Например, Иван" :value="name" @input="onNameInput" />
    </label>
    <button type="button" class="city-find-form__next-btn ui-btn -cta" :disabled="!name" @click="clickNext">
      <span class="ui-btn__text">Далее</span>
      <span class="ui-btn__icon">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M11.2269 8.99998L4 1.69811L5.68067 0L14.5882 8.99998L5.68067 18L4 16.3019L11.2269 8.99998Z"
            fill="white"
          /></svg
      ></span>
    </button>
  </div>
</template>

<script>
export default {
  name: 'StepFour',
  data() {
    return {
      isValidNumber: false,
    }
  },
  computed: {
    name() {
      return this.$store.state.pages.home.professionalsCloser.form.name
    },
  },
  methods: {
    onNameInput(e) {
      this.$store.dispatch('pages/home/professionalsCloser/updateFormField', {
        fieldName: 'name',
        fieldValue: e.target.value,
      })
    },
    clickNext() {
      this.$store.dispatch('pages/home/professionalsCloser/iterateStep')
    },
  },
}
</script>

<style scoped></style>
