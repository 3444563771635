<template>
  <div class="index-page">
    <IndexBanner :title="seo.h1" />
    <PopularServices />
    <HowItWorks />
    <Examples :examples="examples" />
    <ProfessionalsCloser />
    <IndexBestInstallers />
    <MostPopularInBlog
      v-if="posts.length > 0"
      title="Популярное в блоге"
      subtitle="Лайфхаки, инсайды и прочее о ремонте, стройке и другие сложных вещах"
      :posts="posts"
    />
    <AboutRehau />
  </div>
</template>

<script>
import IndexBanner from '@/components/index/IndexBanner.vue'
import IndexBestInstallers from '@/components/index/IndexBestInstallers'
import AboutRehau from '@/components/shared/AboutRehau'
import MostPopularInBlog from '@/components/shared/BlogMostPopular/MostPopularInBlog.vue'
import Examples from '@/components/shared/Examples/Examples'
import HowItWorks from '@/components/shared/HowItWorks/HowItWorks'
import PopularServices from '@/components/shared/PopularServices'
import ProfessionalsCloser from '@/components/shared/ProfessionalsCloser/ProfessionalsCloser'
import { examples } from '@/data/index/index-examples-data'
import { fetchPopular } from '@/utilities/most-popular.utility'
import { fetchSeo } from '@/utilities/seo.utility'

export default {
  components: {
    IndexBanner,
    PopularServices,
    HowItWorks,
    Examples,
    IndexBestInstallers,
    ProfessionalsCloser,
    AboutRehau,
    MostPopularInBlog,
  },
  async asyncData() {
    const seo = await fetchSeo('MAIN', 'Монтаж систем отопления и водоснабжения')

    const posts = await fetchPopular()

    return { seo, posts }
  },
  data() {
    return {
      examples,
    }
  },
  head() {
    return {
      title: this.seo.title,
      meta: [{ hid: 'description', name: 'description', content: this.seo.description }, this.nofollowMeta],
    }
  },
  computed: {
    nofollowMeta() {
      return this.$route.query.utm_referrer ? { name: 'google', content: 'noindex, follow' } : {}
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/index/indexPage.scss';
</style>
