<template>
  <div class="square-link-card" @click="$emit('card-click')">
    <div class="square-link-card__top">
      <div class="square-link-card__icon">
        <img :src="img" alt="" height="35" width="35" />
      </div>
      <svg
        class="square-link-card__arrow"
        width="9"
        height="14"
        viewBox="0 0 9 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M1 1L7 7L1 13" stroke="#0D99FF" stroke-width="2" />
      </svg>
    </div>
    <div class="square-link-card__text">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'PreviewListBox',
  props: {
    title: {
      type: String,
      required: true,
    },
    img: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
@import '/assets/scss/variables.scss';

.square-link-card {
  padding: 24px;
  background-color: $white;
  cursor: pointer;

  &__top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 46px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: $verdigris;
  }

  &__arrow {
    transition: 0.3s;
  }

  &:hover &__arrow {
    transform: translate(5px);
  }

  &__text {
    font-size: 18px;
    font-weight: 700;
    line-height: 25.2px;
    text-align: left;
  }
}
</style>
