<template>
  <div class="works-slide__inner">
    <ExamplesSlideLink v-if="example.to" :example="example" />
    <ExamplesSlideNoLink v-if="!example.to" :example="example" />
  </div>
</template>

<script>
import ExamplesSlideLink from '@/components/shared/Examples/ExamplesSlideLink'
import ExamplesSlideNoLink from '@/components/shared/Examples/ExamplesSlideNoLink'

export default {
  name: 'ExamplesSlide',
  components: {
    ExamplesSlideLink,
    ExamplesSlideNoLink,
  },
  props: {
    example: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
