<template>
  <div class="modal" :class="fullscreenOnMobile ? '-mobile-fullscreen' : ''" @click="clickOnBackground">
    <div class="modal__overlay"></div>
    <div class="modal__wrap">
      <div class="modal__body">
        <button v-if="showCloseButton" class="modal__close" @click="clickModalClose">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="black" xmlns="http://www.w3.org/2000/svg">
            <rect x="4" y="5.41431" width="2" height="22" transform="rotate(-45 4 5.41431)" />
            <rect width="2" height="22" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 20.9706 5.41431)" />
          </svg>
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    showCloseButton: {
      type: Boolean,
      required: true,
    },
    fullscreenOnMobile: {
      type: Boolean,
      required: true,
    },
    modalNameInStore: {
      type: String,
      required: true,
    },
  },
  mounted() {
    const [body] = document.getElementsByTagName('body')
    body.classList.add('modal-opened')
  },
  destroyed() {
    const [body] = document.getElementsByTagName('body')
    body.classList.remove('modal-opened')
    this.$store.dispatch('modules/modals/updateModal', {
      name: this.modalNameInStore,
      status: false,
    })
  },
  methods: {
    clickOnBackground(e) {
      if (e.target.className === 'modal__wrap') {
        this.$store.dispatch('modules/modals/updateModal', {
          name: this.modalNameInStore,
          status: false,
        })
        this.$emit('close')
      }
    },
    clickModalClose() {
      this.$store.dispatch('modules/modals/updateModal', {
        name: this.modalNameInStore,
        status: false,
      })
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss">
@import 'assets/scss/components/modal';
@import 'assets/scss/variables';
.modal-opened {
  overflow: hidden;
  padding-right: 16px;
  @media (max-width: $tablet) {
    overflow: hidden;
    padding-right: 0;
  }
}
</style>
