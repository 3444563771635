import { getImageUrlByLanit } from '@/utilities/image-from-storage.utility'

export const BEST_INSTALLERS = [
  {
    id: 381,
    performerId: 6847,
    sum: 9326892,
    name: 'Ерхов Иван Михайлович',
    experience: 'более 6 лет',
    img: getImageUrlByLanit(977, 384, 384),
  },
  {
    id: 132,
    performerId: 14691,
    sum: 612279.8,
    name: 'Давыдов Виктор',
    experience: 'более 2 лет',
    img: getImageUrlByLanit(28942, 384, 384),
  },
  {
    id: 85,
    performerId: 3003,
    sum: 589705.0,
    name: 'Толстов Дмитрий Витальевич',
    experience: 'более 8 лет',
    img: getImageUrlByLanit(3104, 384, 384),
  },
  {
    id: 502,
    performerId: 14001,
    sum: 458947.5,
    name: 'Живин Александр Владимирович',
    experience: 'более 8 лет',
    img: getImageUrlByLanit(3238, 384, 384),
  },
  {
    id: 416,
    performerId: 126,
    sum: 240651.5,
    name: 'Федорин Илья Юрьевич',
    experience: 'более 8 лет',
    img: getImageUrlByLanit(257, 384, 384),
  },
  {
    id: 309,
    performerId: 1760,
    sum: 173819.9,
    name: 'Григорьев Вадим Михайлович',
    experience: 'более 2 лет',
    img: getImageUrlByLanit(26025, 384, 384),
  },
]
