<template>
  <swiper ref="examplesSwiper" class="works__slider swiper-container swiper" :options="swiperOptions">
    <swiper-slide v-for="(post, index) in posts" :key="index" class="swiper-slide works-slide">
      <MostPopularSlide :post="post" />
    </swiper-slide>
  </swiper>
</template>

<script>
import MostPopularSlide from '@/components/shared/BlogMostPopular/MostPopularSlide'

export default {
  name: 'MostPopularSlider',
  components: {
    MostPopularSlide,
  },
  props: {
    posts: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      swiperOptions: {
        navigation: {
          nextEl: '.works__slider-wrap .swiper-button-next',
          prevEl: '.works__slider-wrap .swiper-button-prev',
        },
        autoplay: {
          delay: 6000,
        },
        spaceBetween: 24,
        slidesPerView: 3,
        breakpoints: {
          1279: {
            spaceBetween: 20,

            slidesPerView: 'auto',
            slidesOffsetBefore: 12,
            slidesOffsetAfter: 12,
          },
          479: {
            spaceBetween: 20,
            slidesPerView: 'auto',
            slidesOffsetBefore: 12,
            slidesOffsetAfter: 12,
          },
        },
      },
      transitionDuration: 6000,
    }
  },
  computed: {
    swiper() {
      return this.$refs.examplesSwiper?.swiper
    },
  },
  mounted() {
    this.initSwiperCallbacks(this.swiper)
  },
  methods: {
    strokeTransition(swiperButtonNext, note) {
      setTimeout(() => {
        const progress = swiperButtonNext.querySelector('.circle__progress--fill')
        const radius = progress.r.baseVal.value
        const circumference = 2 * Math.PI * radius
        const offset = (circumference * (10 - note)) / 10
        progress.style.setProperty('--initialStroke', circumference)
        progress.style.setProperty('--transitionDuration', `${this.transitionDuration}ms`)
        progress.style.strokeDashoffset = offset
      }, 0)
    },
    strokeReset(swiper) {
      const swiperButtonNext = swiper.el.parentNode.querySelector('.swiper-button-next')
      const note = parseFloat(swiperButtonNext.dataset.note)
      const progress = swiperButtonNext.querySelector('.circle__progress--fill')
      progress.classList.remove('js-hidden')
      progress.style.setProperty('--transitionDuration', '0ms')
      progress.style.setProperty('--initialStroke', '0')
      progress.style.strokeDashoffset = '0'
      this.strokeTransition(swiperButtonNext, note)
    },
    strokeDisable(swiper) {
      const swiperButtonNext = swiper.el.parentNode.querySelector('.swiper-button-next')
      const progress = swiperButtonNext.querySelector('.circle__progress--fill')
      progress.classList.add('js-hidden')
    },
    initSwiperCallbacks(swiper) {
      swiper.on('init', () => {
        const swiperButtonNext = swiper.el.parentNode.querySelector('.swiper-button-next')
        const note = parseFloat(swiperButtonNext.dataset.note)
        this.strokeTransition(swiperButtonNext, note)
        this.strokeReset(swiper)
      })
      swiper.on('transitionEnd', () => {
        if (this.swiper.autoplay.running) {
          this.strokeReset(swiper)
        }
      })
      swiper.on('slideChangeTransitionStart', () => {
        if (!this.swiper.autoplay.running) {
          this.strokeDisable(swiper)
        }
      })
      swiper.on('update', () => {
        this.strokeReset(swiper)
      })
    },
  },
}
</script>
