<template>
  <div class="city-find-form__step" :class="extended ? '' : 'city-find-form__step--last'">
    <label class="city-find-form__label ui-form__label">
      <span class="ui-form__label-text">Ваш телефон</span>
      <InputPhoneMask class="ui-form__input" :value="phone" @input="onPhoneInput" />
    </label>
    <StepThreeSendButton v-if="!extended" :is-valid-number="isValidNumber" />
    <StepThreeNextButton v-if="extended" :is-valid-number="isValidNumber" />
    <p v-if="!extended" class="city-find-form__agree">
      Нажимая на кнопку «Отправить заявку» я даю согласие на обработку моих персональных данных в соответствии с
      <button type="button" target="_blank" @click="showConsentModal">условиями</button>.
    </p>
    <PersonalDataConsentModal />
  </div>
</template>

<script>
import InputPhoneMask from '@/components/InputPhoneMask'
import PersonalDataConsentModal from '@/components/shared/PersonalDataConsentModal'
import StepThreeNextButton from '@/components/shared/ProfessionalsCloser/ProfessionalsCloserSteps/StepThree/StepThreeNextButton'
import StepThreeSendButton from '@/components/shared/ProfessionalsCloser/ProfessionalsCloserSteps/StepThree/StepThreeSendButton'

export default {
  name: 'StepThree',
  components: {
    StepThreeSendButton,
    InputPhoneMask,
    StepThreeNextButton,
    PersonalDataConsentModal,
  },
  props: {
    extended: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValidNumber: false,
    }
  },
  computed: {
    phone() {
      return this.$store.state.pages.home.professionalsCloser.form.phone
    },
  },
  methods: {
    onPhoneInput(e) {
      this.$store.dispatch('pages/home/professionalsCloser/updateFormField', {
        fieldName: 'phone',
        fieldValue: e.value,
      })
      this.isValidNumber = e.isValid
    },
    showConsentModal() {
      this.$store.dispatch('modules/modals/updateModal', {
        name: 'personalDataConsent',
        status: true,
      })
    },
  },
}
</script>

<style scoped></style>
