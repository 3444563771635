import { render, staticRenderFns } from "./StepFive.vue?vue&type=template&id=422b84d7&scoped=true"
import script from "./StepFive.vue?vue&type=script&lang=js"
export * from "./StepFive.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "422b84d7",
  null
  
)

export default component.exports