<template>
  <client-only>
    <section id="popular-in-blog" class="works">
      <div class="container">
        <MostPopularTitle :title="title" :subtitle="subtitle" />
      </div>

      <div class="works__slider-wrap">
        <div class="works__slider-arrows index-page__slider-arrows">
          <SwiperCircleArrows :posts="posts" />
        </div>
        <MostPopularSlider :posts="posts" />
      </div>
    </section>
  </client-only>
</template>

<script>
import MostPopularSlider from '@/components/shared/BlogMostPopular/MostPopularSlider'
import MostPopularTitle from '@/components/shared/BlogMostPopular/MostPopularTitle'
import SwiperCircleArrows from '@/components/shared/Swiper/SwiperCircleArrows'

export default {
  name: 'MostPopularInBlog',
  components: {
    SwiperCircleArrows,
    MostPopularTitle,
    MostPopularSlider,
  },
  props: {
    posts: {
      type: Array,
      default() {
        return []
      },
    },
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/index/examples.scss';
</style>
