import axios from 'axios'

import { getConfig } from '@/configs/app.configs'
import { COMPANY } from '@/constants/global.constant'

export async function fetchSeo(pageSlug, pageName, isMO) {
  const defSeo = {
    title: `${pageName} - ${COMPANY.fullName}`,
    h1: pageName,
    description: `${pageName}. Клуб сертифицированных монтажников ${COMPANY.name} `,
  }

  try {
    const res = await axios.get('/custapi/allrest/contents/listcontents', {
      baseURL: getConfig('backUrl'),
      params: { contPage: pageSlug, page: '1' },
    })

    const { list } = res.data
    defSeo.h1 = list.find((item) => item.section === 'h1')?.value ?? defSeo.h1
    if (isMO) {
      defSeo.title = pageName
    } else {
      defSeo.title = list.find((item) => item.section === 'title')?.value ?? defSeo.title
    }

    defSeo.description = list.find((item) => item.section === 'description')?.value ?? defSeo.description

    return defSeo
  } catch (e) {
    return defSeo
  }
}
