<template>
  <client-only>
    <section id="public-best-installers" class="best-workers">
      <div class="container">
        <div class="best-workers__inner">
          <h2 class="best-workers__title ui-h2 index-page__section-title">
            {{ title }}
          </h2>
          <div class="best-workers__text index-page__section-subtitle" v-html="subtitle"></div>
        </div>
      </div>
      <div class="best-workers__slider-wrap">
        <div class="best-workers__slider-arrows index-page__slider-arrows">
          <div class="swiper-button-prev">
            <svg width="20" height="16" viewBox="0 0 20 16" fill="black" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.59844 0.679687L0.398438 7.87969L7.59844 15.0797L9.29644 15.0797L2.69644 8.47969L19.5984 8.47969V7.27969L2.69644 7.27969L9.29644 0.679687L7.59844 0.679687Z"
              />
            </svg>
          </div>
          <div class="swiper-button-next" data-note="0">
            <div class="circle">
              <svg width="36" height="36" class="circle__svg">
                <circle cx="18" cy="18" r="16" class="circle__progress circle__progress--path"></circle>
                <circle cx="18" cy="18" r="16" class="circle__progress circle__progress--fill"></circle>
              </svg>
            </div>
            <svg
              width="20"
              height="16"
              viewBox="0 0 20 16"
              fill="black"
              xmlns="http://www.w3.org/2000/svg"
              class="swiper-button-next__svg"
            >
              <path
                d="M12.3977 15.0801L19.5977 7.88008L12.3977 0.680077L10.6997 0.680077L17.2997 7.28008L0.397658 7.28008L0.397658 8.48008L17.2997 8.48008L10.6997 15.0801L12.3977 15.0801Z"
              />
            </svg>
          </div>
        </div>

        <swiper
          ref="bestInstallersSwiper"
          class="best-workers__slider swiper-container swiper"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="(installer, index) in BEST_INSTALLERS"
            :key="index"
            class="swiper-slide best-workers__slide best-workers-slide"
          >
            <router-link class="best-workers-slide__link" :to="`/profile/${installer.performerId}`">
              <div class="best-workers-slide__img-wrap">
                <img
                  :src="installer.img"
                  :alt="`Фотография монтажника ${installer.name}`"
                  loading="lazy"
                  class="best-workers-slide__img"
                />
                <div class="best-workers-slide__rank">
                  <span class="best-workers-slide__rank-text">Лучший</span>
                  <span class="best-workers-slide__rank-empty"> </span>
                </div>
              </div>
              <div class="best-workers-slide__info">
                <h3 class="best-workers-slide__name">{{ installer.name }}</h3>

                <div class="best-workers-slide__chars">
                  <div class="best-workers-slide__line">
                    <span class="best-workers-slide__line-title ui-body-small">Опыт работы:</span>
                    <span class="best-workers-slide__line-text">{{ installer.experience }}</span>
                  </div>
                </div>
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </section>
  </client-only>
</template>

<script>
import { BEST_INSTALLERS } from '@/constants/index/best-installers.constants'

export default {
  name: 'IndexBestInstallers',
  components: {},
  data() {
    return {
      title: 'Лучшие монтажники месяца',
      subtitle: '<p>Все специалисты проходят обязательную сертификацию</p>',
      installers: [],
      swiperOptions: {
        navigation: {
          nextEl: '.best-workers .swiper-button-next',
          prevEl: '.best-workers .swiper-button-prev',
        },
        autoplay: {
          delay: 6000,
        },
        spaceBetween: 24,
        slidesPerView: 3,
        breakpoints: {
          1279: {
            spaceBetween: 20,

            slidesPerView: 'auto',
            slidesOffsetBefore: 12,
            slidesOffsetAfter: 12,
          },
          479: {
            spaceBetween: 20,
            slidesPerView: 'auto',
            slidesOffsetBefore: 12,
            slidesOffsetAfter: 12,
          },
        },
      },
      transitionDuration: 6000,
      BEST_INSTALLERS,
    }
  },
  computed: {
    swiper() {
      return this.$refs.bestInstallersSwiper?.swiper
    },
  },
  mounted() {
    if (this.swiper) {
      this.initSwiperCallbacks(this.swiper)
    }
  },
  methods: {
    strokeTransition(swiperButtonNext, note) {
      setTimeout(() => {
        const progress = swiperButtonNext.querySelector('.circle__progress--fill')
        const radius = progress.r.baseVal.value
        const circumference = 2 * Math.PI * radius
        const offset = (circumference * (10 - note)) / 10
        progress.style.setProperty('--initialStroke', circumference)
        progress.style.setProperty('--transitionDuration', `${this.transitionDuration}ms`)
        progress.style.strokeDashoffset = offset
      }, 0)
    },
    strokeReset(swiper) {
      const swiperButtonNext = swiper.el.parentNode.querySelector('.swiper-button-next')
      const note = parseFloat(swiperButtonNext.dataset.note)
      const progress = swiperButtonNext.querySelector('.circle__progress--fill')
      progress.classList.remove('js-hidden')
      progress.style.setProperty('--transitionDuration', '0ms')
      progress.style.setProperty('--initialStroke', '0')
      progress.style.strokeDashoffset = '0'
      this.strokeTransition(swiperButtonNext, note)
    },
    strokeDisable(swiper) {
      const swiperButtonNext = swiper.el.parentNode.querySelector('.swiper-button-next')
      const progress = swiperButtonNext.querySelector('.circle__progress--fill')
      progress.classList.add('js-hidden')
    },
    initSwiperCallbacks(swiper) {
      swiper.on('init', () => {
        const swiperButtonNext = swiper.el.parentNode.querySelector('.swiper-button-next')
        const note = parseFloat(swiperButtonNext.dataset.note)
        this.strokeTransition(swiperButtonNext, note)
        this.strokeReset(swiper)
      })
      swiper.on('transitionEnd', () => {
        if (this.swiper.autoplay.running) {
          this.strokeReset(swiper)
        }
      })
      swiper.on('slideChangeTransitionStart', () => {
        if (!this.swiper.autoplay.running) {
          this.strokeDisable(swiper)
        }
      })
      swiper.on('update', () => {
        this.strokeReset(swiper)
      })
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/scss/index/_best-workers.scss';
</style>
