// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icons/angle-right-white.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/icons/angle-right-pink.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ui-button[data-v-12ddf55a]{align-items:center;border:0;border-radius:0;color:#fff;cursor:pointer;display:flex;height:9vw;justify-content:center}.ui-button-inner[data-v-12ddf55a]{font-size:4.6875vw;font-weight:500;padding:0 3.125vw}.ui-button-angle[data-v-12ddf55a]{height:9vw;width:9vw}@media(min-width:1021px){.ui-button[data-v-12ddf55a]{height:2.63543vw}.ui-button-inner[data-v-12ddf55a]{font-size:1.1713vw;line-height:2.63543vw;padding:0 .73206vw}.ui-button-angle[data-v-12ddf55a]{height:2.63543vw;width:2.63543vw}}@media(min-width:1457px){.ui-button[data-v-12ddf55a]{height:39px}.ui-button-inner[data-v-12ddf55a]{font-size:16px;padding:0 11px}.ui-button-angle[data-v-12ddf55a]{height:39px;width:39px}}.pink[data-v-12ddf55a]{background:linear-gradient(90deg,#000 50%,#e50040 0) 100% 100%;background-size:200% 100%;color:#fff;transition:all .2s ease-out}.pink[data-v-12ddf55a]:hover{background-position:0 100%}.pink:active .ui-button-angle[data-v-12ddf55a]{background:#000}.pink .ui-button-angle[data-v-12ddf55a]{background:#e50040 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%}.white[data-v-12ddf55a]{background:transparent;color:#e50040;transition:all .2s ease-out}.white[data-v-12ddf55a]:hover{background-position:0 100%}.white .ui-button-angle[data-v-12ddf55a]{background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
