<template>
  <section class="banner">
    <IndexSlider />
    <div class="banner__highlights">
      <div class="container">
        <div class="banner__highlights-list">
          <div class="banner__hightlights-item">
            <div class="banner__hightlights-value">3 500</div>
            <div class="banner__hightlights-text ui-body-small">принятых заказов на проектирование и установку</div>
          </div>
          <div class="banner__hightlights-item">
            <div class="banner__hightlights-value">
              {{ certifiedInstallersCount }}
              {{ test }}
            </div>
            <div class="banner__hightlights-text ui-body-small">монтажников, сдавших сертификационный тест</div>
          </div>
          <div class="banner__hightlights-item">
            <div class="banner__hightlights-value">95%</div>
            <div class="banner__hightlights-text ui-body-small">
              клиентов рекомендуют монтажников {{ COMPANY.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IndexSlider from '@/components/index/IndexSlider.vue'
import { COMPANY } from '@/constants/global.constant'

export default {
  name: 'IndexBanner',
  components: { IndexSlider },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      COMPANY,
      certifiedInstallersCount: '6500',
      test: '',
    }
  },
  async fetch() {
    this.certifiedInstallersCount = await this.$axios.$get('/custapi/certifiedmontagecount')
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/index/banner';
</style>
